@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
      @apply mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl;
    }
    h2 {
      @apply mt-8 text-2xl font-bold tracking-tight text-gray-900;
    }
    p {
        @apply mt-6
    }
  }